import React, { useEffect, useState } from "react";
import { AuthService } from "hooks/AuthService";
import axiosInstance from "config/instance";
import { NavLink } from "react-router-dom";
import appConfig from "config/appConfig"
import store from "store";

const LoginSuccess = ({ history }) => {
  const [error, setError] = useState(false);
  const { dispatch } = store;
  const { localStorage: { tokenKey, userKey } } = appConfig;
  useEffect(
    () => {
      AuthService.getInstance()
        .completeLogin()
        .then(({ access_token, profile }) => {
          localStorage.setItem(tokenKey, access_token);
          localStorage.setItem(userKey, JSON.stringify(profile));

          axiosInstance.defaults.headers.common.Authorization = `Bearer ${access_token}`;

          dispatch({
            type: "SET_LOGGED"
          });
          history.push("/");
        })
        .catch(() => {
          setError(true);
        });
    },
    [dispatch, history]
  );

  return (
    <div style={{ marginLeft: "300px" }}>
      <div className="sign-up">
        {!error && (
          <h3 style={{ textAlign: "center" }}> Token Validation .... </h3>
        )}
        {error && (
          <h3 style={{ textAlign: "center" }}>
            Invalid Token Please go to the login page <NavLink to={"/login"} />
          </h3>
        )}
      </div>
    </div>
  );
};

export default LoginSuccess;
