import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Spin, Typography } from "antd";
import "./layout.css";
import axiosInstance from "../../config/instance";
import Logout from "components/Logout"

const { Header, Content, Footer, Sider } = Layout;

const { Text } = Typography;

const fetchedMenu = subMenus => (
  <Menu
    mode="inline"
    defaultSelectedKeys={["0"]}
    defaultOpenKeys={["sub1"]}
    style={{ height: "100%", borderRight: 0, paddingTop: 80 }}
  >
    <Menu.Item key="0">
      <Link to="/">
        <Icon type="pie-chart" />
        <span>Dashboard</span>
      </Link>
    </Menu.Item>
    {subMenus.map((element, key) => (
      <Menu.Item key={key + 1}>
        <Link to={`/${element}`}>
          <Icon type="notification" />
          <span>{element}</span>
        </Link>
      </Menu.Item>
    ))}
  </Menu>
);

const LayoutContainer = props => {
  const [collapsed, setCollapsed] = useState(false);
  const [subMenus, setSubMenus] = useState([]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    axiosInstance
      .get("/messages/apps")
      .then(({ data }) => {
        setSubMenus(data);
        props.dispatch({
          type: "SET_APPS",
          payload: data
        });
      })
      .catch(() => {});
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ background: "white" }}
      >
        <div className="logo" />
        {fetchedMenu(subMenus)}
      </Sider>
      <Layout>
        <Header className="header-layout">
          <div className="header-content" />
          <div className="header-content">
            <a href="/">xNotif - Monitor your application</a>
          </div>
          <div style={{marginRight: 20}} className="header-content cursor-pointer">
            <Text type="danger" onClick={Logout}>Logout</Text>
            <Icon style={{color: "#fa541c", paddingLeft: 8}} type="logout" />
          </div>
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }} />
          <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
            <Spin tip="Loading..." style={{ top: 40 }} spinning={props.loader}>
              {props.children}
            </Spin>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>xNotif - Copyright</Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ main }) => ({ loader: main.isLoading });
export default connect(mapStateToProps)(LayoutContainer);
