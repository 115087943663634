import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "store";
import Routes from "routes";
import "antd/dist/antd.less";
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
