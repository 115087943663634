import { connect } from "react-redux";
import { AuthService } from "hooks/AuthService";
import appConfig from "config/appConfig"

const PrivateRoutes = props => {
  const { isLogged } = props;
  const { localStorage: { tokenKey } } = appConfig;
  const isAuthenticated = localStorage.getItem(tokenKey);
  if (!isLogged && !isAuthenticated) {
    AuthService.getInstance().login();
  }
  return isAuthenticated ? props.children : null;
};

const mapStateToProps = ({ main }) => ({ isLogged: main.isLogged });

export default connect(
  mapStateToProps,
  null
)(PrivateRoutes);
