const appConfig = {
  API_BASE_URL:
    process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8999/api",
  KEYCLOAK_URL:
    process.env.REACT_APP_KEYCLOAK_URL ||
    "https://keycloak.dev.x-hub.io/auth/realms/xhub",
  localStorage: {
    tokenKey: "tokenKey",
    userKey: "userKey",
  },
  languages: ["en", "fr"]
};

export default appConfig;
