const testInitialState = {
  apps: [],
  isLoading: false,
  isTest: true,
  isLogged: false
};

const mainReducer = (state = testInitialState, action) => {
  switch (action.type) {
    case "SET_APPS":
      return { ...state, apps: action.payload };
    case "TOGGLE_LOADER":
      return { ...state, isLoading: !state.isLoading };
    case "SET_LOGGED":
      return { ...state, isLogged: true };
    case "NO_TEST":
      return { ...state, isTest: false };

    default:
      return { ...state };
  }
};

export default mainReducer;
