import loadable from "react-loadable";
import { LoaderInner } from "./Loader/Loader";

const componentLoader = opts =>
  // console.log({ ...opts });
  loadable({
    loading: LoaderInner,
    delay: 1000,
    ...opts
  });
export default componentLoader;
