import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import componentLoader from "components/ComponentLoader/ComponentLoader";
import FourOhFour from "views/FourOhFour";
import Layout from "views/Layout/Layout";
import PrivateRoutes from "components/PrivateRoutes";
import { connect, useSelector } from "react-redux";
import LoginSuccess from "components/LoginSuccess";

const Home = componentLoader({
  loader: () => import("views/Home" /* webpackChunkName: "react-table" */)
});
const Messages = componentLoader({
  loader: () =>
    import("components/Messages/Messages" /* webpackChunkName: "react-table" */)
});

const Routes = props => {
  const apps = useSelector(({ main }) => main.apps);
  return (
    <Router basename={`${process.env.PUBLIC_URL}/monitor`}>
      <Switch>
        <Route exact path="/loginSuccess" component={LoginSuccess} />
        <PrivateRoutes>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              {apps.map(route => (
                <Route
                  exact
                  path={`/${route}`}
                  component={props => <Messages appName={route} {...props} />}
                />
              ))}
              <Route exact path="*" component={FourOhFour} />
            </Switch>
          </Layout>
        </PrivateRoutes>
      </Switch>
    </Router>
  );
};

export default connect()(Routes);
