import axios from "axios";
import appConfig from "config/appConfig";
import Logout from "../components/Logout";

const {
  localStorage: { tokenKey }
} = appConfig;
const token = localStorage.getItem(tokenKey);

const axiosInstance = axios.create({
  baseURL: appConfig.API_BASE_URL
});

if (token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axiosInstance.interceptors.response.use(undefined, error => {
  const { response } = error;
  //if (!response || response.status === 401) {
  if (response.status === 401) {
    Logout();
  }
  return Promise.reject(error);
});

export default axiosInstance;
