import { UserManager } from "oidc-client";
import appConfig from "config/appConfig";

export class AuthService {
  static instance;

  userManager;

  constructor() {
    const settings = {
      authority: appConfig.KEYCLOAK_URL,
      client_id: "xnotif-front",
      redirect_uri: window.location.origin.concat("/monitor/loginSuccess"),
      response_type: "id_token token",
      scope: "openid profile email"
    };
    this.userManager = new UserManager(settings);
  }

  static getInstance() {
    if (AuthService.instance === undefined) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  async isAuthenticated() {
    return new Promise((resolve, reject) => {
      this.getUser().then(user => {
        resolve(user != null && user.access_token != null && !user.expired);
      });
    });
  }

  completeLogin() {
    return this.userManager.signinRedirectCallback();
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }
}
